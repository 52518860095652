<template>
	<div class="page">
		<div class="title">
			<div class="titleL">会员基本信息</div>
			<div class="titleR">
				<el-button type="infor" @click="$router.back()">返回</el-button>
			</div>
		</div>
		<div class="tit">
			<div class="titL">
				<span class="titText">会员资料</span>
				<img class="editImg" src="../../../assets/img/shouyin/edit.png" v-if="$buttonAuthority('huiyuan-huiyuanList-see-editUser')"  @click="dialogEditUser_state=true"/>
			</div>
			<div class="titR">
				<el-dropdown @command="toOrder">
					<el-button type="infor">
					订单记录<i class="el-icon-arrow-down el-icon--right"></i>
					</el-button>
					<el-dropdown-menu slot="dropdown">
						<el-dropdown-item :command="{title:'消费订单',path:'/order/orderList'}" >消费订单</el-dropdown-item>
						<el-dropdown-item :command="{title:'充值订单',path:'/order/rechargeOrderList'}">充值订单</el-dropdown-item>
						<el-dropdown-item :command="{title:'退货订单',path:'/order/returnOrderList'}" >退换货订单</el-dropdown-item>
					</el-dropdown-menu>
				</el-dropdown>
			</div>
		</div>
		<!-- 会员资料 -->
		<div class="msgBox">
			<el-row>
				<el-col class="msgItem" :span="6">
					姓名：{{userDetail.basicInfo.nickName}} 
					<img v-if="userDetail.basicInfo.sex==='男'" class="sexImg" src="../../../assets/img/huiyuan/nan.png"/>
					<img v-if="userDetail.basicInfo.sex==='女'" class="sexImg" src="../../../assets/img/huiyuan/nv.png"/>
				</el-col>
				<el-col class="msgItem" :span="6">
					电话：{{userDetail.basicInfo.userMobile}} 
				</el-col>
				<el-col class="msgItem" :span="6">
					卡号：{{userDetail.cardInfo.cardId}} 
				</el-col>
			</el-row>
			<el-row>
				<el-col class="msgItem" :span="6">
					家庭住址：{{userDetail.basicInfo.homeAddress}} 
				</el-col>
				<el-col class="msgItem" :span="6">
					紧急联系人：{{userDetail.basicInfo.urgentUser}} 
				</el-col>
				<el-col class="msgItem" :span="6">
					紧急联系电话：{{userDetail.basicInfo.urgentUserMobile}} 
				</el-col>
				<el-col class="msgItem" :span="6">
					最后消费时间：{{$public.FTime(userDetail.basicInfo.consumptionTime)}} 
				</el-col>
			</el-row>
			<el-row>
				<el-col class="msgItem" :span="6">
					会员备注：{{userDetail.basicInfo.remark}} 
				</el-col>
			</el-row>
		</div>
		<div class="tit">
			<div class="titL">
				<span class="titText">会员资产</span>
			</div>
			<div class="titR">
			</div>
		</div>
		<!-- 资产 -->
		<div class="msgBox1">
			<div class="msgItem1" @click="$router.push({path:'/huiyuan/huiyuanYueSee?id='+userDetail.id})">
				<img class="msgItem1L" src="../../../assets/img/huiyuan/yue.png"/>
				<div class="msgItem1R">
					<div class="name">会员余额</div>
					<div class="num">{{userDetail.cardInfo.userYue}}  <img class="nextImg" src="../../../assets/img/huiyuan/next.png"/></div>
				</div>
			</div>
			<div class="msgItem1" @click="$router.push({path:'/huiyuan/huiyuanJifenSee?id='+userDetail.id})">
				<img class="msgItem1L" src="../../../assets/img/huiyuan/jifen.png"/>
				<div class="msgItem1R">
					<div class="name">会员积分</div>
					<div class="num">{{userDetail.cardInfo.userJifen}}  <img class="nextImg" src="../../../assets/img/huiyuan/next.png"/></div>
				</div>
			</div>
			<div class="msgItem1" @click="$router.push({path:'/huiyuan/huiyuanXiaofeikaSee?id='+userDetail.id})">
				<img class="msgItem1L" src="../../../assets/img/huiyuan/xiaofeika.png"/>
				<div class="msgItem1R">
					<div class="name">消费卡</div>
					<div class="num">{{virtualCount.cardCount}} 张 <img class="nextImg" src="../../../assets/img/huiyuan/next.png"/></div>
				</div>
			</div>
			<div class="msgItem1" style="border:0;" @click="$router.push({path:'/huiyuan/huiyuanYouhuiquanSee?id='+userDetail.id})">
				<img class="msgItem1L" src="../../../assets/img/huiyuan/youhuiquan.png"/>
				<div class="msgItem1R">
					<div class="name">优惠券</div>
					<div class="num">{{virtualCount.couponCount}} 张 <img class="nextImg" src="../../../assets/img/huiyuan/next.png"/></div>
				</div>
			</div>
		</div>
		<div class="tit">
			<div class="titL">
				<span class="titText">宠物资料</span>
			</div>
			<div class="titR">
				<el-button type="primary" v-if="$buttonAuthority('huiyuan-huiyuanList-see-addPet')" @click="curPetItem={};dialogEditPet_state=true">添加宠物</el-button>
			</div>
		</div>
		<!-- 宠物资料 -->
		<div class="petList">
			<el-table :data="userDetail.pets_info?userDetail.pets_info:[]" style="width: 100%" height="100%">
				<el-table-column label="昵称">
					<template slot-scope="scope">

						{{scope.row.nickName}}({{scope.row.categoryInfo.name}})<img v-if="scope.row.vipPet" class="VipImg" src="../../../assets/img/shouyin/VIP.png"/>
					</template>
				</el-table-column>
				<el-table-column label="种类">
					<template slot-scope="scope">
						{{scope.row.applyType.title}}
					</template>
				</el-table-column>
				<el-table-column label="性别">
					<template slot-scope="scope">
						{{scope.row.petSex}}
					</template>
				</el-table-column>
				<el-table-column label="出生日期">
					<template slot-scope="scope">
						{{$public.FTime(scope.row.bronDate,"YYYY-MM-DD")}}
					</template>
				</el-table-column>
				<el-table-column label="年龄">
					<template slot-scope="scope">
						<span v-if="$public.FAge(scope.row.bronDate)[0]>0">
							{{$public.FAge(scope.row.bronDate)[0]}}岁
						</span>
						<span v-if="$public.FAge(scope.row.bronDate)[1]>0">
							{{$public.FAge(scope.row.bronDate)[1]}}月
						</span>
					</template>
				</el-table-column>
				<el-table-column label="犬证编号">
					<template slot-scope="scope">
						{{scope.row.petCertificateNo}}
					</template>
				</el-table-column>
				<el-table-column label="体重">
					<template slot-scope="scope">
						{{scope.row.weight}}kg
					</template>
				</el-table-column>
				<el-table-column label="芯片编号" width="150">
					<template slot-scope="scope">
						{{scope.row.epromNo}}
					</template>
				</el-table-column>
				<el-table-column label="状态">
					<template slot-scope="scope">
						{{scope.row.status.name}}
					</template>
				</el-table-column>
				<el-table-column label="备注">
					<template slot-scope="scope">
						{{scope.row.remark}}
					</template>
				</el-table-column>
				<el-table-column label="操作" width="150">
					<template slot-scope="scope">
						<el-button type="text" size="small" @click="$router.push({path:'/huiyuan/petSee?userId='+userDetail.id+'&petId='+scope.row.id})">查看</el-button>
						<el-button v-if="$buttonAuthority('huiyuan-huiyuanList-see-editPet')" type="text" size="small"  @click="curPetItem=scope.row;dialogEditPet_state=true">编辑</el-button>
						<el-button v-if="$buttonAuthority('huiyuan-huiyuanList-see-delPet')" type="text" size="small" @click="curPetItem=scope.row;deletePet()">删除</el-button>
					</template>
				</el-table-column>
			</el-table>
		</div>
		<div v-if="dialogEditUser_state">
			<dialogEditUser :userInfo="userDetail" @submit="submit_dialogEditUser"></dialogEditUser>
		</div>
		<div v-if="dialogEditPet_state">
			<dialogEditPet :userInfo="userDetail" :curPetItem="curPetItem" @submit="submit_dialogEditPet"></dialogEditPet>
		</div>
	</div>
</template>
<script>
	import dialogEditUser from "../../../components/dialogEditUser.vue"
	import dialogEditPet from "../../../components/dialogEditPet.vue"
	export default {
		components: {dialogEditUser,dialogEditPet},
		data() {
			return {
				userDetail:{
					id:'',
					basicInfo:{},
					cardInfo:{},
					companyInfo:{},
					status:{},
					vipGrades:{},
					pets_info:[],
				},
				virtualCount:{cardCount:0, couponCount:0},
				// 编辑用户信息
				dialogEditUser_state:false,
				// 编辑宠物
				curPetItem:{},
				dialogEditPet_state:false,
			}
		},
		computed:{
			
			
		},
		mounted() {
			this.getDetail();
			this.getCount();
		},
		methods: {
			// 编辑用户信息
			submit_dialogEditUser(obj){
				this.dialogEditUser_state = false;
				if(obj.type==='success'){
					this.getDetail();
				}
			},
			// 编辑宠物信息
			submit_dialogEditPet(obj){
				this.dialogEditPet_state = false;
				if(obj.type==='success'){
					this.getDetail();
				}
			},
			// 删除宠物
			deletePet(){
				this.$confirm('此操作将永久删除该宠物, 是否继续?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					this.$http.delete('/customer/Pet/Delete', {id:this.curPetItem.id}).then(res => {
						if (res.code == 0) {
							this.$message({
								type: 'success',
								message: '删除成功!'
							});
							this.getDetail();
						}
					})
				})
			},
			// 获取用户详情
			getDetail(){
				let params = {
					id:this.$route.query.id
				}
				let loading = this.$loading()
				this.$http.get("/customer/Member/Get",params).then(res=>{
					loading.close()
					if(res.code===0){
						this.userDetail = res.data
					}
				})
			},
			getCount(){
				let params = {
					MemberId:this.$route.query.id,
				}
				this.$http.get("/customer/Member/GetServiceCount",params).then(res=>{
					if(res.code===0){
						this.virtualCount = res.data
					}
				})
			},
			// 跳转订单详情
			toOrder(str){
				let obj = str
				this.$store.commit("setCurNav2_"+obj.path.split('/')[1],{title:obj.title,path:obj.path})
				this.$router.push({path:obj.path,query:{str:this.$route.query.id,nickName:this.userDetail.basicInfo.nickName}})
			},
		}
	}
</script>
<style lang="scss" scoped>
	.page {
		width:100%;height: 100%;border-radius: 20px;overflow: hidden;color: $fontColor;background-color: #fff;
		.title{
			display: flex;justify-content: space-between;border-bottom: 1px solid #f2f2f2;height: 68px;box-sizing: border-box;padding:14px 20px;
			.titleL{
				font-size: 16px;color:$fontColor;line-height: 40px;
			}
			.titleR{
				
			}
		}
		.tit{
			padding:32px 20px 8px;display: flex;justify-content: space-between;
			.titL{
				font-size: 16px;color:$fontColor;background: url(../../../assets/img/cangku/detailName.png) 2px 8px no-repeat;background-size: 24px 24px;
				.titText{
					padding-left:12px;display:inline-block;line-height: 40px;
				}
				.editImg{
					width:20px;height:20px;margin-left:8px;vertical-align: middle;cursor: pointer;
				}
			}
		}
		.msgBox{
			margin:0 20px;background-color: #FFFCF8;padding:20px 30px;box-sizing: border-box;
			.msgItem{
				font-size: 14px;color:$fontColor;line-height: 40px;
				.sexImg{
					width:20px;height: 20px;vertical-align: middle;
				}
			}
		}
		.msgBox1{
			margin:0 20px;background-color: #FFFCF8;padding:20px 30px;box-sizing: border-box;display: flex;height: 90px;
			.msgItem1{
				display: flex;width:25%;justify-content: center;border-right: 1px solid #E3E2E2;cursor: pointer;
				.msgItem1L{
					width:46px;height: 46px;vertical-align: middle;margin-right: 16px;
				}
				.msgItem1R{
					.name{
						font-size: 14px;color:$fontColor;margin-bottom: 16px;
					}
					.num{
						font-size: 14px;color:$mainColor;
						.nextImg{
							width:20px;vertical-align: middle;
						}
					}
				}
			}
		}
		.petList{
			height: calc(100% - 558px);
		}
	}
</style>
